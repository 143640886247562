export const addTitle = "הוסף";
export const editTitle = "ערוך";
export const deleteTitle = "מחק";
export const saveTitle = "save";

export const addCategoryTitle = "הוסף קטגוריה";
export const editCategoryTitle = "ערוך קטגוריה";
export const deleteCategoryTitle = "מחק קטגוריה";
export const categoriesTitle = "קטגוריות";

export const agreementNumberTitle = "הסכם מספר";
export const toolNameTitle = "שם הכלי";
export const serialNumberTitle = "מספר סידורי";
export const costPerDayTitle = "עלות ליום";
export const costPerWeek = "עלות לשבוע";
export const costPerMonth = "עלות לחודש";
export const printTitle = "הדפס";
export const fullNameTitle = "שם מלא";
export const companyNameTitle = "שם החברה";
export const phoneNumberTitle = "מספר טלפון";
export const homePhoneNumberTitle = "טלפון בבית";
export const faxNumberTitle = "מספר פקס";
export const idNumberTitle = "מספר זהות";
export const addressTitle = "כתובת";
export const modelTitle = "מודל";
export const actionTitle = "פעולות";
export const showTitle = "הצג";
export const removeThisProductTitle = "הסר ממוצר זה";
export const addNewDiagramTitle = "הוסף דיאגרמה חדשה";
export const connectDiagramTitle = "חבר דיאגרמה";
export const nameInEnglishTitle = "שם באנגלית";
export const nameInHebrewTitle = "שם בעברית";
export const priceTitle = "מחיר";
export const diagramTitle = "דיאגרמה";
export const nameOfTheServiceTitle = "שם הטיפול";
export const whenToServiceTitle = "מתי לטפל";
export const doneDateTitle = "בוצע בתאריך";
export const editDescriptionTitle = "ערוך תיאור";
export const markAsDoneTitle = "סמן בוצע";
export const amountInInventoryTitle = "כמות במלאי";
export const productOrCategoriesDisplayedOnPageTitle = "מוצרים/קטגוריות מוצגות בדף";
export const showAsTitle = "show as";
export const loadsRequestTitle = "טוען בקשה";
export const propNameTitle = "שם התכונה";
export const valueTitle = "ערך";
export const rentalAgreementTitle = "מספר הסכם";
export const fromDateTitle = "מתאריך";
export const untilDateTitle = "עד תאריך";
export const rentalAgreementOpenTitle = "הסכם פתוח";
export const clientNameTitle = "שם לקוח";
export const workingToolLocationTitle = "מיקום עבודה של הכלי";
export const locationTitle = "מיקום";
export const toolsTitle = "כלים";
export const closeRentalAgreementTitle = "סגור הסכם";
export const deleteRentalAgreementTitle = "מחק הסכם";
export const fuelAmountTitle = "כמות דלק";
export const fuelPriceTitle = "מחיר דלק";
export const transportAmountTitle = "כמות הובלות";
export const transportPriceTitle = "מחיר הובלות";
export const signatureTitle = "חתימה";
export const selectClientTitle = "בחר לקוח";
export const problemTitle = "בעיה";
export const updateTitle = "עדכון";
export const costTitle = "עלות";
export const partsTitle = "חלקים";
export const serviceBookTitle = "ספר תחזוקה";
export const closeTitle = "סגור";
export const categoryTitle = "קטגוריה";
export const productNameTitle = "שם המוצר'";
export const clearTitle = "clear";
export const addProductTitle = "הוסף מוצר";
export const deleteProductTitle = "מחק מוצר";
export const imagesTitle = "תמונות";
export const propsTitle = "תכונות";
export const descriptionTitle = "תיאור";
export const diagramsTitle = "דיאגרמות";
export const serviceTitle = "טיפולים";
export const emailTitle = "מייל";
export const ashdodBranchTitle = "סניף אשדוד";
export const telTitle = "טל";
export const contactAsTitle = 'צור קשר';
export const newsAndUpdatesTitle = "עדכונים וחדשות";